import { getSectionStyle } from '@/utils'

function getCoverText(title, content) {
  if (title) return `<h1>${title}</h1> ${content}`

  return content
}

export default function () {
  const get = path => this.$path(path, this.$store.state)

  function getSubpageSlug(source) {
    return (
      (
        (get('Root.item.posts') || []).find(
          post => post.customFieldsConfig?.[0]?.source === source,
        ) || {}
      ).slug || '/'
    )
  }

  return [
    {
      component: 'Announcement',

      data: {
        content: get('Page.item.customFields.announcement'),
      },
    },
    {
      component: 'Slider',
      data: get('Page.item.customFields.cover'),
      props: {
        aspectRatio: '16/9',
        class: 'hero__slider',

        options: {
          manualNavigation: false,
        },
      },

      blocks: {
        default: [
          {
            component: 'Section',
            condition:
              get('Page.item.customFields.page-hero.title') ||
              get('Page.item.customFields.page-hero.content') ||
              (get('Page.item.customFields.page-hero-buttons') || []).filter(f => {
                return f.content && f.type
              }).length,
            props: {
              size: 'medium',
              class: `hero__overlay hero__overlay-placement--${get(
                'Page.item.customFields.page-hero.placement',
              ) || 'center'}`,
            },
            blocks: {
              default: [
                {
                  component: 'Text',
                  condition:
                    get('Page.item.customFields.page-hero.title') ||
                    get('Page.item.customFields.page-hero.content'),
                  props: {
                    style: 'margin-bottom: 1rem',
                  },
                  data: {
                    content: getCoverText(
                      get('Page.item.customFields.page-hero.title'),
                      get('Page.item.customFields.page-hero.content'),
                    ),
                  },
                },
                {
                  component: 'Flex',
                  condition: (get('Page.item.customFields.page-hero-buttons') || []).filter(f => {
                    return f.content && f.type
                  }).length,

                  props: {
                    class: 'hero__overlay-button-wrapper',
                    flexWrap: 'wrap',
                  },
                  blocks: {
                    default: (get('Page.item.customFields.page-hero-buttons') || [])
                      .filter(button => {
                        return Boolean(button.content)
                      })
                      .map(button => {
                        return {
                          component: 'Button',
                          data: {
                            label: button.content,

                            to:
                              button.type === 'contacts'
                                ? { name: 'Contact' }
                                : getSubpageSlug(button.type),
                            theme: button.type === 'contacts' ? 'secondary' : 'primary',
                            icon: button.type === 'contacts' ? '' : ['fas', 'arrow-right'],

                            tag: 'router-link',
                          },
                        }
                      }),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    ...(get('Page.item.customFields.additional-sections') || []).map((i, idx, arr) => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          showResponsivePadding: true,
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
          style: getSectionStyle(i, arr[idx - 1], arr[idx + 1])
        },
      }
    }),

    {
      component: 'Section',
      condition:
        get('Page.item.customFields.contact-cta.content') ||
        get('Page.item.customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('Page.item.customFields.contact-cta.content'),
                  data: {
                    content: get('Page.item.customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('Page.item.customFields.contact-cta.button'),
                  data: {
                    label: get('Page.item.customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'primary',
                    tag: 'router-link',
                    icon: ['fas', 'arrow-right'],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
