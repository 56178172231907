<template>
  <Loader :value="promises.fetch" theme="default" class="page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/page'

// custom components
import Page from '@/components/Page'
import Portal from '@/components/Portal'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Page', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { ...Page, Portal }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.page {
  .hero {
    $height: 90vh;
    height: $height;

    $resposiveHeight: 70vh;

    &__slider {
      .kpb-slider__slider {
        height: $height;
        @include respond-below('phone') {
          height: $resposiveHeight;
        }
      }

      iframe {
        min-height: $height;
      }
    }

    @include respond-below('phone') {
      height: $resposiveHeight;
    }

    &__overlay {
      top: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      bottom: initial;
      right: initial;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      align-items: center;
      color: white;
      font-size: clamp(1rem, 1.4rem, 2rem);
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

      h1 {
        font-size: clamp(0.5rem, 6vh, 7vh);
        margin: 0;

        @include respond-below('phone') {
          font-size: clamp(0.5rem, 4.5vh, 6vh);
        }
      }

      .elder-button {
        border-color: white;
        border-width: 2px;

        &__label {
          text-shadow: 0 2px 2px rgba(black, 0.5);
        }

        &--primary {
          background: rgba(white, 0.3);
        }

        &--secondary {
          background: transparent;
        }
      }

      a[data-cta] {
        font-size: 1rem;
        border-color: white !important;
        border-style: solid;
        border-width: 2px !important;
        color: white;

        &[data-cta='primary'] {
          background: rgba(white, 0.3) !important;
          text-shadow: 0 1px 2px rgba(black, 0.5);
        }

        &[data-cta='secondary'] {
          background: transparent !important;
        }
      }

      .kpb-text {
        max-width: 920px;
        margin: 0;
      }

      &-placement {
        &--center {
          align-items: center;
          justify-content: center;
          text-align: center;
          .kpb-text {
            text-align: center;
          }
        }
        &--left {
          text-align: left;

          @include respond-above('phone') {
            padding-inline: 3rem;
          }
        }
        &--none {
          display: none;
        }
      }

      &-button-wrapper {
        display: inline-flex;
        padding: 0;
        gap: 1rem 2rem;
        text-align: center;
        margin: 1rem 0;
      }
    }
  }

  .project-portal {
    &-wrapper {
      @include respond-below('phone') {
        align-items: flex-start;
        gap: 0.5rem;
      }
    }

    &-button {
      border-color: var(--brand-primary);
      border-width: 2px;
    }
  }
}
</style>
