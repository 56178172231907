<template>
  <div class="article-portal">
    <h3 class="article-portal__title">{{ item.title }}</h3>
    <div v-html="`${item.content}`.substring(0, 100) + '...'" class="article-portal__content"></div>

    <div class="article-portal__read-more">
      <span class="article-portal__read-more-content">{{ $tc('readMore') }}</span>
      <FontAwesomeIcon :icon="['far', 'arrow-right']" class="article-portal__read-more-icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'cards',
      enums: ['cards', 'masonry'],
    },
  },
  methods: {
    getStatus(item) {
      if (categoryMap[item]) return categoryMap[item]

      return categoryMap['development']
    },
  },
}
</script>

<style lang="scss">
.article-portal {
  word-break: normal;
  color: var(--primary-contrast);

  &__content {
    h2 {
      color: initial;
      font-family: var(--primary-font);
      font-size: 0.9rem;
      margin-bottom: 0;
    }

    p {
      margin: 0em;
    }
  }

  &__title {
    margin-block: 0.5rem;
  }

  &__read-more {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.8rem;

    &-content {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
    }
  }
}
</style>
