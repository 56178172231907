<template>
  <div class="project-card" :class="`project-card--${theme}`">
    <template v-if="theme === 'masonry'">
      <span class="project-card__name">{{ item.name }}</span>
    </template>
    <template v-else-if="theme === 'tiles'">
      <span v-if="status" class="project-card__status">{{ status }}</span>
      <div class="project-card__content">
        <h3 class="project-card__name">{{ item.name }}</h3>

        <span v-if="$path('address.city', item)" class="project-card__address">
          {{ item.address.city }}
        </span>

        <span class="project-card__view-project">
          {{ $t('viewProject') }}
        </span>
      </div>
    </template>

    <template v-else>
      <div class="project-card__content">
        <div class="project-card__title">
          <h3>{{ item.name }}</h3>
          <FontAwesomeIcon :icon="['far', 'arrow-right']" class="project-card__arrow" />
        </div>

        <div v-if="housingTypes" class="project-card__house-types">
          <FontAwesomeIcon
            :icon="[
              'fal',
              ($path('metadata.residentialProperties.saleType', item) || []).includes('rent')
                ? 'building'
                : 'house',
            ]"
            class="project-card__icon"
          />
          <span class="project-card__stats project-card__stats-property">{{ housingTypes }}</span>
        </div>
        <span v-if="$path('address.city', item)" class="project-card__address">
          <FontAwesomeIcon :icon="['fal', 'map-marker-alt']" class="project-card__icon" /><span
            class="project-card__stats project-card__stats-city"
          >
            {{ item.address.city }}
          </span>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'cards',
      enums: ['cards', 'masonry', 'tiles'],
    },
  },
  computed: {
    status() {
      let saleTypes = this.$path('item.metadata.residentialProperties.saleType') || []
      if (saleTypes.includes('rent')) return this.$t(`residentialStatus:rent.${this.item.status}`)

      return this.$t(`residentialStatus:sale.${this.item.status}`)
    },
    housingTypes() {
      let types = this.item?.metadata?.residentialProperties
      if (!types?.propertyType) return
      return types.propertyType
        .map(type => {
          let propertyType = 'propertyType:' + type

          /*
          liten potensiel bug,
          hvis de har feks bolig til 200000kvm og kontor til 100000kvm
          så vises bolig til 200000kvm på kontor.
          */
          if (type === 'office') {
            if (Math.max(types.useableArea))
              return this.$tc(propertyType) + ' - ' + Math.max(types.useableArea) + ' kvm'
          }

          return this.$tc(propertyType)
        })
        .splice(0, 4)
        .join(' | ')
    },
  },
}
</script>

<style lang="scss">
.project-card {
  $breakAt: 850px;

  &--masonry {
    .project-card__name {
      font-size: 0.9em;
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      padding: 0.5rem 0.8rem;
      background: rgba(black, 0.6);
      color: white;
      border-radius: 5px;
      transition: opacity 200ms ease-out;
      pointer-events: none;
    }
  }

  &--card {
    .project-card {
      &__title {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;

        h3 {
          @include header-font(1.4rem);
          margin: 0.3em 0;
        }
      }

      &__content {
        color: var(--primary-contrast);

        svg {
          font-size: 0.7em;
          margin-right: 0.3em;
        }
      }

      &__address {
        text-transform: uppercase;
        font-size: 0.6em;

        svg {
          margin-right: 0.5em;
          margin-left: 0.1em;
          font-size: 1.2em;
        }
      }
    }
  }

  &--tiles {
    .project-card {
      &__status {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 11;
        color: white;
        background: var(--primary);
        font-size: 0.8em;
        text-align: center;
        padding: 0.75rem;
      }

      &__content {
        color: white;
        font-size: 1.5rem;

        position: absolute;
        inset: 0;
        z-index: 10;
        pointer-events: none;
        background: rgba(0, 0, 0, 0.4);
        transition: background 0.2s ease;
        display: grid;
        gap: 1rem;
        place-content: center;
        text-align: center;
      }

      &__name {
        margin: 0;
      }

      &__address {
        margin: 0;
        font-size: 0.7em;
        margin-bottom: 1rem;
      }

      &__view-project {
        margin: 0;
        font-size: 0.7em;
        text-decoration: underline;
        margin-top: auto;
        text-underline-offset: 0.3em;
        text-decoration-thickness: 1px;
      }
    }
  }
}
</style>
