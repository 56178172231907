<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__container container">
        <a href="https://vedal.no" class="footer__title">
          <h3>Vedal AS</h3>
        </a>
        <div class="footer__contact">
          <template v-if="companyInfo.email">
            <a class="footer__contact-email" :href="`mailto:${companyInfo.email}`">
              {{ $t('email') }}: {{ companyInfo.email }}
            </a>
            <a class="footer__contact-phone" :href="`tel:${(companyInfo.phone || '').trim()}`">
              {{ $t('phone') }}: {{ companyInfo.phone }}
            </a>
            <router-link to="/kontakt-oss">Kontakt oss</router-link>
          </template>
        </div>

        <div class="footer__policy footer__clickable">
          <a :href="`/api/legal/privacy/page/${item.id}`" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </a>

          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />
          <div class="footer__copyright">
            &copy; {{ year }} -

            <span class="footer__bottom">
              {{ $t('pageMadeBy') }}
              <a
                id="https://www.kvass.no"
                :href="`https://www.kvass.no?ref=${currentUrl}`"
                target="_blank"
                >Kvass</a
              >
            </span>
          </div>
        </div>
        <FooterContent
          v-if="customFooterContent.length"
          :value="customFooterContent"
          class="footer__custom-content"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    customFooterContent() {
      if (!this.item) return
      let content = GetCustomField('footer-content', this.item.customFields) || []
      if (!content.every(Boolean)) return []
      return content
    },
    companyInfo() {
      if (!this.item) return
      return GetCustomField('company-info', this.item.customFields) || {}
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },

    currentUrl() {
      return encodeURIComponent(window.location.origin)
    },

    logo() {
      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
  metaInfo() {
    return {
      script: [{ src: 'https://unpkg.com/@kvass/widgets@1.0.10/dist/contact.js' }],
    }
  },

  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  --footer-gap: 0.5rem;

  background-color: var(--primary);
  color: var(--primary-contrast);

  .container {
    padding-inline: var(--section-padding-inline) !important;
    max-width: calc(1280px + calc(var(--section-padding-inline) * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  &__title {
    grid-column: 1/-1;
  }

  &__clickable {
    & > button:hover,
    & > a:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    object-fit: contain;
    object-position: left;
    max-height: 50px;
    width: 100%;
  }

  &__content {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 4rem;

    @include respond-below('tablet') {
      gap: 2rem;
      grid-template-columns: 1fr;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--footer-gap);
  }

  &__contact-email {
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }
  &__contact-phone {
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  --menu-margin: 1rem;

  &__custom-content {
    @include respond-above('tablet') {
      margin-top: var(--menu-margin);
    }
  }

  &__policy {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--footer-gap);
    white-space: nowrap;

    & > * {
      width: fit-content;
    }
  }

  &__menu {
    .elder__navigation-component-icon-right {
      display: none;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--footer-gap);

    @include respond-above('tablet') {
      margin-left: auto;
      margin-top: var(--menu-margin);
    }

    .elder__navigation-component {
      padding: 0;
    }

    &-action {
      cursor: pointer;
      width: fit-content;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[id='https://www.kvass.no']
    {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.1rem;
        width: 100%;
        height: 1px;
        background-color: currentColor;
      }
    }
  }

  p {
    margin-block: 0 var(--footer-gap);
  }
}
</style>
