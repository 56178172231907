<script setup>
import { computed } from 'vue'

const props = defineProps({
	value: Number,
	itemsCount: {
		type: Number,
		required: true
	},
	itemsPerPage: {
		type: Number,
		default: 10
	}
})

const emit = defineEmits(['input'])

function setPage(page) {
	emit('input', page)
}

function isNumberOutside(number, firstNumber, lastNumber) {
	if (number <= firstNumber) return null
	if (number >= lastNumber) return null
	return number
}

const _pages = computed(() => {
	const length = Math.ceil(props.itemsCount / props.itemsPerPage)
	const numbers = Array.from({ length }).fill(null)

	const firstNumber = 0
	const lastNumber = length - 1

	if (lastNumber == firstNumber) return [firstNumber]

	numbers[firstNumber] = firstNumber
	numbers[lastNumber] = lastNumber

	const prevNumber = isNumberOutside(props.value - 1, firstNumber, lastNumber)
	const currentNumber = isNumberOutside(props.value, firstNumber, lastNumber)
	const nextNumber = isNumberOutside(props.value + 1, firstNumber, lastNumber)

	if (prevNumber) {
		numbers[prevNumber] = prevNumber
	}
	if (currentNumber) {
		numbers[currentNumber] = currentNumber
	}
	if (nextNumber) {
		numbers[nextNumber] = nextNumber
	}

	let lastNull = false

	return numbers
		.map((item, index) => {
			// show number
			if (item != null) {
				lastNull = false
				return item
			}

			// if only 1 null between numbers, show number instead of ...
			if (numbers[index - 1] != null && numbers[index + 1] != null) {
				return index
			}

			if (!lastNull) {
				lastNull = true
				return '...'
			}

			return null
		})
		.filter(item => item !== null)
})
</script>

<template>
	<nav v-if="itemsCount > itemsPerPage" aria-label="pagination" class="portal-pagination">
		<ul class="portal-pagination__list">
			<li :class="['portal-pagination__list-item', { 'portal-pagination__list-item--hidden': value <= 0 }]">
				<button @click="setPage(value - 1)">
					<FontAwesomeIcon :icon="['fal', 'chevron-double-left']" />
					Forrige
				</button>
			</li>
			<li v-for="page in _pages" :key="page" class="portal-pagination__list-item">
				<button @click="setPage(page)" :aria-current="page === value ? 'page' : null" :disabled="typeof page !== 'number'">{{ typeof page === 'number' ? page + 1 : page }}</button>
			</li>
			<li :class="['portal-pagination__list-item', { 'portal-pagination__list-item--hidden': value + 1 >= Math.ceil(itemsCount / itemsPerPage) }]">
				<button @click="setPage(value + 1)">
					Neste
					<FontAwesomeIcon :icon="['fal', 'chevron-double-right']" />
				</button>
			</li>
		</ul>
	</nav>
</template>

<style lang="scss">
.portal-pagination {
	margin-block-start: 2rem;

	&__list {
		margin-inline: auto;
		width: max-content;
		padding: 0;

		&-item {
			list-style: none;
			display: inline-block;

			&:not(:is(:last-child, :first-child)) {
				aspect-ratio: 1;
			}

			&:is(:last-child, :first-child) {
				font-size: .9em;
			}

			button {
				padding: .5rem .75rem;
				color: inherit;
				line-height: 1;
				font: inherit;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: .5rem;

				&:hover {
					background-color: rgba(black, .1);
				}

				&[aria-current="page"] {
					background-color: var(--primary);
					color: var(--primary-contrast);
				}
			}

			&--hidden {
				visibility: hidden;
			}
		}
	}
}
</style>